import { vue } from '@/main.js';
import api from '@/api';
import SymbolSearchRequest from '@/model/symbol/SymbolSearchRequest';

export default {
  namespaced: true,
  state: {
    statusIds: [],
    tickers: {},
    groups: [],
  },
  getters: {
    groups: state => state.groups,
    statusIds: state => state.statusIds,
    statusById: state => id => state.statusIds.find(status => status.id === id),
    getTicker: state => id => state.tickers[id],
    tickersMap: state => state.tickers,
  },
  mutations: {
    updateData(state, data) {
      state.groups = data;
    },
    tickerUpdate(state, tickers) {
      if (tickers == null) {
        return;
      }
      let savedTickers = { ...state.tickers };
      tickers.forEach(ticker => {
        savedTickers[ticker.symbolId] = ticker;
      });
      state.tickers = savedTickers;
      vue.$root.$emit('system::tickers::updated');
    }
  },
  actions: {
    refreshGroupData({ commit }) {
      /*
            return Promise.resolve();
      */
      return api.base()
        .get('/trading/symbolGroup/data')
        .then(
          response => {
            commit('updateData', response.data.data);
          }
        );
    },
    getAllSymbol(actions, {
      filter,
      pagination,
      sort
    }) {
      return api.base()
        .post('/trading/symbol/get/all',
          SymbolSearchRequest({
            ...(filter || {}),
            pagination,
            sort,
          })
        )
        .then(response => (
          {
            data: response.data.data,
            pagination: {
              page: response.data.metadata.page,
              total: response.data.metadata.total
            },
            sort: response.data.metadata.sort
          })
        );
    },
    getAvailableSymbols({ state, commit }, account) {
      console.log('getAvailableSymbols', account);
      if (state.lastUpdated != null && state.lastUpdated > Date.now() - 2 * 60 * 60 * 1000) {
        return Promise.resolve(state.symbols);
      }
      return api.base()
        .get('/trading/symbol/available-symbols', { params: { account } })
        .then(
          response => {
            //commit('setSymbols', response.data.data);
            return response.data.data;
          }
        );
    },
    getSymbolById({ commit }, id) {
      console.log('getSymbolById', id);
      return api.base()
        .get('/trading/symbol/get/id',{params: { id }})
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    updateSymbol({ commit }, {
      symbol,
      id
    }) {
      return api.base()
        .put('/trading/symbol/update', symbol, {params: { id }})
        .then(
          response => {
            return response.data.data;
          }
        );
    },

    createSymbol({ commit }, { symbol }) {
      return api.base()
        .post('/trading/symbol/add', symbol)
        .then(
          response => {
            return response.data.data;
          }
        );
    },
    restartFeeds({ commit }) {
      return api.base()
        .get('/trading/symbol/restart-feeds');

    },
    handleEvent({ commit }, {
      id,
      event
    }) {
      return api.base()
        .post('/trading/symbol/handleEvent/' + id + '/' + event)
        .then(
          response => {
            return response.data.data;
          }
        );
    },

  }

};
