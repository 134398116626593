<template>
  <b-modal no-fade v-model="dialog.show" v-bind:size="dialog.showGraph ? 'xl' : 'lg'" :ref="`madal-client-${uniqueId}`"
           :id="`madal-client-${uniqueId}`"
           :hide-footer="true" centered @close="onDialogClose"
           no-close-on-backdrop
  >
    <template #modal-title>

      <div class="d-inline-block" v-if="isNew">
        {{ accountLogin }} - {{ $t('trade.newTrade') }} - {{ tradeDetails.symbolId }}
      </div>
      <div class="d-inline-block" v-else>
        {{ accountLogin }} | {{ $t('trade.editTrade') }} - {{ tradeDetails.symbolId }} ({{ tradeDetails.order }})
      </div>
    </template>
    <div>
      <div v-if="account != null">
        <trade-order-form-part v-model="tradeDetails" @placeNewOrder="sendOrder" @reopen="reopenOrder"
                               v-bind:isNew="isNew" v-bind:is-closed="!tradeDetails.isOpen"
                               v-bind:stopPricesValidation="stopPricesValidation"
                               @showGraph="showGraph=>dialog.showGraph=showGraph"
        >
          <template #newTrade>
            <!--Data-->
            <b-row>
              <!--Graph-->
              <b-col cols="5" style="min-height: 300px" v-if="dialog.showGraph">
                <t-v-graph v-bind:selected-symbol="selectedSymbol"/>
              </b-col>

              <b-col v-bind:cols="dialog.showGraph ? 6 : 12">
                <!-- Symbol Data -->
                <trade-order-symbol-data-part v-model="tradeDetails" v-bind:dialog="dialog" v-bind:lastTick="lastTick" v-bind:symbols="symbols"/>
                <!--Trade Price New-->
                <trade-order-new-prices-part v-if="isNew" v-model="tradeDetails" v-bind:account="account" v-bind:lastTick="lastTick"
                                             v-bind:symbols="symbols" v-bind:stopPricesValidation="stopPricesValidation"
                />
                <!--Trade Price Edit-->
                <trade-order-edit-price-part v-else v-model="tradeDetails" v-bind:account="account" v-bind:trade="trade" v-bind:symbols="symbols"
                                             v-bind:lastTick="lastTick" v-bind:stopPricesValidation="stopPricesValidation"
                />
                <!--Commission-->
                <trade-order-commission-part v-model="tradeDetails"/>
                <!--Comment-->
                <b-row class="mt-1">
                  <b-col cols="12">
                    <b-input-group size="sm" prepend="comment" style="width: 100%">
                      <b-input v-model="tradeDetails.comment"></b-input>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-col>

            </b-row>
          </template>
          <template #revision>
            <trade-order-new-revision-part v-if="isNew" v-model="tradeDetails"/>
            <trade-order-edit-revision-part v-else v-model="tradeDetails"/>
          </template>
        </trade-order-form-part>
      </div>
      <div v-else class="text-center">
        <b-spinner/>
      </div>
    </div>
  </b-modal>
</template>

<script>
import TradeOrderSymbolDataPart from '@/components/modal/tradeOrderModalPart/tradeOrderSymbolDataPart.vue'
import TradeOrderEditPricePart from '@/components/modal/tradeOrderModalPart/tradeOrderEditPricePart.vue'
import TradeRequest from '@/model/trade/TradeRequest'
import { isSell, TradeCmdEnum } from '@/model/trade/TradeCmdEnum'
import { mapActions, mapGetters } from 'vuex'
import TradeOrderEditRevisionPart from '@/components/modal/tradeOrderModalPart/tradeOrderEditRevisionPart.vue'
import TradeOrderFormPart from '@/components/modal/tradeOrderModalPart/tradeOrderFormPart.vue'
import TradeOrderNewPricesPart from '@/components/modal/tradeOrderModalPart/TradeOrderNewPricesPart.vue'
import TradeOrderNewRevisionPart from '@/components/modal/tradeOrderModalPart/TradeOrderNewRevisionPart.vue'
import TradeOrderCommissionPart from '@/components/modal/tradeOrderModalPart/tradeOrderCommissionPart.vue'
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import TVGraph from '@/components/graph/TVGraph.vue'

export default {
  name: 'tradeOrderModal',
  mixins: [mixinBase, mixinNotifications],
  components: {
    TVGraph,
    TradeOrderCommissionPart,
    TradeOrderNewRevisionPart,
    TradeOrderNewPricesPart,
    TradeOrderFormPart,
    TradeOrderEditRevisionPart,
    TradeOrderEditPricePart,
    TradeOrderSymbolDataPart
  },
  data: () => ({
    dialog: {
      show: false,
      showGraph: false,
    },
    account: null,
    trade: {},
    tradeDetails: TradeRequest(),
    symbols: [{ name: null }],
    lastTick: {},
  }),
  watch:{
    ['tradeDetails.symbolId'](){
      this.refreshTicker()
    }
  },
  mounted() {
    // this.intervalId = setInterval(this.refreshTicker, 5*1000)
    this.$root.$on('system::tickers::updated', this.refreshTicker)
  },
  beforeDestroy() {
    //  clearInterval(this.intervalId)
    this.$root.$off('system::tickers::updated', this.refreshTicker)
  },
  computed: {
    ...mapGetters('symbol', ['getTicker']),
    selectedSymbol(){
      if(this.tradeDetails == null || this.tradeDetails.symbolId == null || this.symbols == null){
        return null
      }
      return this.symbols.find(symbol => symbol.id === this.tradeDetails.symbolId)
    },
    uniqueId() {
      return this.$options.name + this._uid
    },
    isNew() {
      return this.trade == null || this.trade.order == null;
    },
    stopPricesValidation() {
      let stopPrices = {tp:true, sl:true}
      if(this.trade == null || this.tradeDetails == null){
        return stopPrices
      }
      let symbol = this.symbols.find(symbol => symbol.id === this.tradeDetails.symbolId)
      let currentPrice = isNaN(this.tradeDetails.openPrice) ? 0 : this.tradeDetails.openPrice
      if(symbol == null || currentPrice == null){
        return stopPrices
      }
      stopPrices.tp = !this.tradeDetails.isOpen || this.tradeDetails.tp == null || this.tradeDetails.tp === "" || (!isNaN(this.tradeDetails.tp) && this.tradeDetails.tp > currentPrice)
      stopPrices.sl = !this.tradeDetails.isOpen || this.tradeDetails.sl == null || this.tradeDetails.sl === "" || (!isNaN(this.tradeDetails.sl) && this.tradeDetails.sl < currentPrice)
      return stopPrices
    },
    accountLogin() {
      if (this.account != null && this.account.id != null) {
        return this.account.id
      }
      if (this.trade != null && this.trade.account != null) {
        return this.trade.account
      }
      return null
    }
  },
  methods: {
    ...mapActions('symbol', ['getAvailableSymbols']),
    ...mapActions('trade', ['getLastTick', 'placeOrder', 'requestReopenOrder', 'editOrder']),
    ...mapActions('account', ['getAccountDataByLogin']),
    async showModal(account, tradeDetails) {
      this.trade = tradeDetails
      this.tradeDetails = TradeRequest({
        command: TradeCmdEnum.BUY,
        symbolId: 'EUR/USD',
        lots: 0.1,
        openPrice: 0,
        currentOpenTime: true,
        currentOpenPrice: true,
        openTime: this.$moment()
            .format('YYYY-MM-DD HH:mm'),
        ...(tradeDetails || {})
      })
      this.dialog.show = true
      if (account == null) {
        this.account = await this.getAccount()
      } else {
        this.account = account
      }
      this.refreshSymbols()
      this.loading = true
    },
    closeModal() {
      this.dialog.show = false
      this.onDialogClose()
    },
    onDialogClose() {
      this.account = null
      this.trade = null
      this.dialog.showGraph = false
      this.tradeDetails = TradeRequest()
      this.$emit('close')
    },
    async getAccount(){
      const accountData =  await this.getAccountDataByLogin(this.trade.account)
      console.log("getAccount", accountData.account)
      return accountData.account;
    },
    async refreshSymbols() {
      console.log('refreshSymbols')
      try{
        this.symbols = await this.getAvailableSymbols(this.accountLogin);
      }catch (e) {
        this.showServerErrorNotification('fetchDataError', 'symbol', e)
        this.closeModal();
      }finally{
        this.loading = false
      }
    },
    async refreshTicker() {
      if (this.dialog.show !== true) return
      console.log('refreshTicker', this.tradeDetails.symbolId)
      let lastTick = this.getTicker(this.tradeDetails.symbolId)
      if (lastTick == null) {
        let tick = await this.getLastTick({
          account: this.accountLogin,
          symbols: [this.tradeDetails.symbolId]
        });
        if(Array.isArray(tick) && tick.length>0) {
          this.lastTick = tick[0]
        }
      } else {
        console.log('lastTick', lastTick)
        this.lastTick = lastTick
      }
    },
    async syncPrice(key) {
      await this.refreshTicker()
      this.tradeDetails[key] = isSell(this.tradeDetails.command) ? this.lastTick.bid : this.lastTick.ask
    },
    reopenOrder(form){
      let $this = this;
      let request = this.requestReopenOrder({
        account: this.accountLogin,
        order: { ...this.tradeDetails },
        orderId: this.tradeDetails.order
      })
      this.onServerResponse(request, form).then(() => {
        $this.$emit('order-reopened', this.tradeDetails)
      })
    },
    sendOrder(form) {
      let request = null
      if (this.isNew) {
        request = this.placeOrder({
          account: this.accountLogin,
          order: this.tradeDetails
        })
      } else {
        request = this.editOrder({
          account: this.accountLogin,
          order: this.tradeDetails,
          orderId: this.tradeDetails.order
        })
      }
      this.onServerResponse(request, form)
    },
    onServerResponse(request, form){
      let $this = this
      return request.then(order => {
        $this.showSuccessNotification(null, $this.$t('globalMessages.placeOrderSuccess', {orderId: order.order}))
        $this.closeModal()
        $this.$emit('order-placed', order)
      }, error => {
        $this.showErrorNotification($this.$t('globalMessages.placeOrderError',), $this.getErrorDescription(error))
      })
          .finally(() => {
            form.busy = false
          })
    }
  }
}
</script>

<style scoped>

</style>
