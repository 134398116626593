<template>
  <div v-bind:key="selectedSymbol">
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container content-screen-2-3">
      <div class="tradingview-widget-container__widget" style="height:calc(100% - 32px);width:100%"></div>
      <div class="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text"></span></a>
      </div>
    </div>
    <!-- TradingView Widget END -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { computed } from '@vue/composition-api'

export default {
  name: 'TVGraph',
  props:{
    selectedSymbol: {
      type: String,
      default: () => null
    }
  },
  data: () => ({
    windowWidth: window.innerWidth,
  }),
  watch: {
    selectedSymbol: {
      handler(){
        setTimeout(this.mountGraph, 300)
      },
      immediate: true
    }
  },
  created() {
    this.$root.$on("system::symbol::selected", this.onSymbolSelected)
    this.$root.$on("system::skin::changed", this.onThemeChanged)
  },
  beforeDestroy() {
    this.$root.$off("system::symbol::selected", this.onSymbolSelected)
    this.$root.$off("system::skin::changed", this.onThemeChanged)
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    // this.mountGraph()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  computed: {
    ...mapGetters('appConfig', ['skin']),
    isDark(){
      return this.skin === 'dark';
    },
    selectedGraphSymbol() {
      if(this.selectedSymbol == null){
        return null
      }
      if(this.selectedSymbol.graphSymbol != null && this.selectedSymbol.graphSymbol.trim() !== ""){
        return this.selectedSymbol.graphSymbol
      }
      let symbolName = this.selectedSymbol.id
      if (symbolName.includes(':')) {
        symbolName = symbolName.split(':')[1] + ':' + symbolName.split(':')[0]
      }
      symbolName = symbolName.replaceAll('/', '')
      return symbolName
    },
  },

  methods: {
    mountGraph() {
      if(this.selectedSymbol == null || this.selectedGraphSymbol == null){
        console.warn('graph selectedSymbol is null')
        return
      }
      let symbol = this.selectedGraphSymbol
      const widgetPlaceholder = document.getElementsByClassName('tradingview-widget-container')[0];
      widgetPlaceholder.replaceChildren(); // empty placeholder
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
      script.async = true;
      script.innerHTML = JSON.stringify( {
        // "autosize": true,
        "height": "310",
        "symbol": symbol,
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": this.isDark ? "dark" : "light",
        "style": "1",
        "locale": "en",
        "editablewatchlist": false,
        "enable_publishing": false,
        "allow_symbol_change": true,
        "hide_legend": true,
        "backgroundColor":  this.isDark ? "rgba(22, 29, 49, 1)" : "rgb(255,255,255)",
        "hide_side_toolbar": true,
        "hide_top_toolbar": false,
        "save_image": false,
        "hide_volume": true,
        "delete_button_in_legend": true,
        "support_host": "https://www.tradingview.com"
      });
      widgetPlaceholder.appendChild(script);
    },
    onThemeChanged(theme){
      setTimeout(this.mountGraph, 300)
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
}
</script>

<style scoped lang="scss">

</style>
